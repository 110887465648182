import styled from "styled-components"

export const BasicTextModuleStyles = styled.section`
  .container {
    > div:first-of-type {
      text-align: center;
      margin: 0 0 32px;
    }
  }
`

export const SkillsWrapperStyles = styled.div`
  .skills {
    &__content {
      display: block;
      align-items: center;
      width: 100%;
      height: auto;
      margin: 16px 0;
      padding: 16px 0;
      justify-content: space-between;
      overflow: hidden;
    }
    &__category {
      display: flex;
      flex-direction: row;
      margin: 0 auto 24px;
      text-align: left;
      flex-direction: column;
      h4 {
        font-size: 16px;
        font-weight: 100;
        color: var(--primary);
        text-transform: uppercase;
        margin: 8px;
      }

      @media (min-width: 768px) {
        width: 66.666%;
        max-width: 700px;
      }

      @media (max-width: 768px) {
        h4 {
          font-size: 12px;
        }
      }
    }
    &__showcase {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      padding: 16px;
      background: #fff;
      border-radius: 0 16px 16px 16px;
      border-left: 4px solid var(--primary);
      border-bottom: 4px solid var(--primary);

      @media (max-width: 480px) {
        padding: 8px;
      }
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 4px;
      padding: 4px;
      flex-direction: column;
      position: relative;

      @media (max-width: 480px) {
        width: 20px;
        height: 20px;
        img {
          width: 20px;
          height: 20px;
        }
      }

      .label {
        position: absolute;
        left: 50%;
        top: -45px;
        transform: translate(-50%, 0%)
        opacity: 0;
        visibility: hidden;
        color: var(--primary);
        background: var(--inActive);
        white-space: nowrap;
        transition: opacity 300ms ease-out;
        font-size: 12px;
        font-weight: 700;
        text-transform: capitalize;
        padding: 4px 8px;
        border-radius: 4px;
        &:after {
            content:'';
            position: absolute;
            top: 90%;
            left: 0%;
            margin-left: 8px;
            width: 0;
            height: 0;
            border-top: solid 8px var(--inActive);
            border-left: solid 8px transparent;
            border-right: solid 8px transparent;
        }
      }

      img {
        transition: transform 300ms ease-out;
      }

      &:hover {
        img {
          transform: scale(1.5) translateY(-5px);
        }
        .label {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 480px) {
          margin: 4px 8px
        }
      }
    }
  }
`

export const RolesWrapperStyles = styled.div`
  .roles {
    &__content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      margin: 16px 0;
      padding: 16px 0;
      overflow: hidden;
    }
    &__icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 4px 4px 0;
      padding: 8px 16px;
      flex-direction: row;
      position: relative;
      width: auto;
      background: #fff;
      border-radius: 24px;

      .label {
        color: #000;
        font-weight: 500;
        font-size: 12px;
      }

      &:hover {
        background: var(--primary);
      }

      @media (max-width: 480px) {
        justify-content: space-between;
        padding: 4px 12px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .label {
          font-weight: 900;
          font-size: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0;
        }
      }
    }
    &__img {
      width: 24px;
      height: 24px;
      margin: 0 16px 0 0;

      @media (max-width: 480px) {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
      }
    }
  }
`

import * as React from "react"

const Skill = ({ image, name }) => {
  return (
    <div className="skills__icon">
      <img src={image.file.url} height={32} width={32} alt={name} />
      <span className="label">{name}</span>
    </div>
  )
}

export default Skill

import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import { techTypes } from "../constants/icons"
import { graphql } from "gatsby"
import Skill from "../components/Skills/Skill"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"

const Index = ({
  data: {
    allContentfulSkill: { group },
  },
}) => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          subTitle="Celebrating the creative works of a digital experience generator."
          enquire={true}
        />
        <PerksModule>
          <Perk
            title="Introduction"
            content="Software Engineer and Product Manager"
          />
        </PerksModule>
        <BasicTextModule
          title="Tech Stack"
          content="Proficient in these tools to build software applications and technologies"
          link="/about"
          linkText="Work Experience"
        >
          <div className="skills__content">
            {group.map(({ fieldValue, edges }) => (
              <div key={fieldValue} className="skills__category">
                <h4>{techTypes[fieldValue]}</h4>
                <div className="skills__showcase">
                  {edges.map(({ node }) => (
                    <Skill key={node.id} {...node} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </BasicTextModule>
        <Features
          title="Featured Projects"
          introduction="Explore some of my collective works."
        />
      </Layout>
    </>
  )
}

export const data = graphql`
  query AllSkills {
    allContentfulSkill {
      group(field: type) {
        fieldValue
        edges {
          node {
            id
            name
            image {
              file {
                url
              }
            }
            type
          }
        }
      }
    }
  }
`

export default Index
